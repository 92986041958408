@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;

	margin: 0;
	padding: 0;

	font: inherit;
}

body {
	font-family: 'Montserrat', sans-serif;
}
