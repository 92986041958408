@use 'base.scss' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.menu-active {
		body {
			@apply overflow-hidden;

			.menu {
				@apply translate-x-0;
			}

			.menu-toggler span:nth-child(2) {
				@apply -translate-x-2;
			}
		}
	}
}
